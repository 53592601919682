import {
  CutlistMaterialGroup,
  CutlistOrder,
  MaterialGroup,
} from '@cutr/constants/cutlist';
import { Theme } from '@cutr/constants/cutlist-theme';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useLeadDetails } from './api/account';
import { useDeliveryAddress } from './api/address';
import { api } from './api/backend';
import { resetErrors, usePartsHaveErrors } from './api/errors';
import { AuthState, useAuthStore, useIsLoggedIn } from './api/login';
import { cacheMaterials, materialsCached } from './api/materials';
import {
  articleCodeToMaterialGroupMapper,
  useGroupBy,
  useMaterialGroupState,
} from './api/materialsGroup';
import { useNestingStore } from './api/nesting';
import {
  conditionallyTogglePricing,
  usePriceExVat,
  usePricingStore,
} from './api/pricing';
import { useCutlistState, useSetTitle, useTitle } from './api/store';
import { useCreateCutlist } from './queries/crud';
import {
  getCurrentFeatures,
  useCurrentFeatures,
  useCurrentSource,
  useTheme,
  useThemeConfig,
} from './theme';
import { isInIframe, urlParamToBoolean } from './utils/misc';
import { SessionStorage } from './utils/storage';

export function useOnClickOutside(handler: () => void) {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);

  return ref;
}

export const onFocusSelect = (e: React.FocusEvent<HTMLInputElement>) => {
  const i = e.currentTarget;
  setTimeout(() => i.select(), 0);
};

// restore focus to the last element when the modal closes
export function useFocusRestore(restoreFocus: boolean) {
  const lastFocussedElement = React.useRef<HTMLElement | null>(null);
  React.useLayoutEffect(() => {
    if (!restoreFocus) {
      lastFocussedElement.current = document.activeElement as HTMLElement;
      return;
    }
    lastFocussedElement.current?.focus();
    lastFocussedElement.current = null;
  }, [restoreFocus]);
}

const CUTLIST_TITLE_MAP: { [key: string]: string } = {
  nl: 'Zaaglijst',
  en: 'Cutlist',
  de: 'Zuschnittsliste',
};
export function useInitCutlist() {
  const { i18n } = useTranslation();
  const isDirty = React.useRef(false);
  const title = useTitle();
  const setTitle = useSetTitle();

  useGroupByMaterial();

  React.useEffect(() => {
    // wait for language
    if (!i18n.resolvedLanguage) return;

    setTimeout(() => (isDirty.current = true), 0);
    if (title || isDirty.current) return;

    setTitle(CUTLIST_TITLE_MAP[i18n.resolvedLanguage]);
  }, [i18n.resolvedLanguage, title]);
}

export function useGroupByMaterial() {
  const groupBy = useGroupBy();

  React.useEffect(groupBy, []);
}

export function useApplyTheme(themeOverride?: Theme) {
  const theme = useTheme((state) => state.theme);
  const config = useThemeConfig();

  const themeToUse = themeOverride || theme;

  const { i18n } = useTranslation();
  if (!i18n.hasLoadedNamespace(themeToUse)) {
    i18n.loadNamespaces(themeToUse);
  }
  i18n.setDefaultNamespace(themeToUse);

  if (isInIframe()) {
    document.body.setAttribute('data-embedded', '');
  }

  React.useEffect(() => {
    SessionStorage.set('theme', themeToUse);
    document.body.setAttribute('data-theme', themeToUse);
    document
      .querySelector('link[rel=icon]')
      ?.setAttribute('href', config.favicon);
  }, [themeToUse]);
}

export function useFetchMaterials() {
  const isLoggedIn = useIsLoggedIn();
  const setHasMaterials = useCutlistState((state) => state.setHasMaterials);
  React.useEffect(() => {
    if (materialsCached()) return;

    api
      .materials()
      .then(cacheMaterials)
      .then(() => setHasMaterials(true));
  }, [isLoggedIn]);
}

export function useLoginByTokenParam() {
  const [searchParams] = useSearchParams();
  const { login } = useAuthStore();
  const inputToken = searchParams.get('token') || '';
  const cutlistId = searchParams.get('cutlistId') || '';
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!inputToken) return;

    const handleLoginByToken = async () => {
      if (!isLoggedIn) {
        const data = await api.loginByToken(inputToken);
        const { email, clientNumber, token } = data;
        login({ email, clientNumber, token });
      }

      navigate(`/cutlist/${cutlistId}/parts`);
      window.analytics.track('Navigated to Cutlist View-Only', {
        cutlistId,
        alreadyLoggedIn: false,
      });
    };

    handleLoginByToken();
  }, [inputToken]);
}

export function useLoginByKeyParam() {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const { mutateAsync: createCutlist } = useCreateCutlist();

  const { login } = useAuthStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!key) return;

    const handleCall = async () => {
      const data = await api.loginByKey(key);
      const { email, clientNumber, token } = data as AuthState;
      login({ email, clientNumber, token });
      searchParams.delete('key');

      const cutlist = await createCutlist();

      navigate(`/cutlist/${cutlist.id}/parts?${searchParams.toString()}`);
    };

    handleCall();
  }, [key]);
}

export function useThirdPartyLogin() {
  const [searchParams] = useSearchParams();
  const { login } = useAuthStore();
  const navigate = useNavigate();
  const [error, setError] = React.useState<unknown>();
  React.useEffect(() => {
    const handleCall = async () => {
      try {
        const tokenParam = searchParams.get('token');

        if (!tokenParam) {
          setError('Token is missing');
          return;
        }

        const data = await api.thirdPartyLogin(tokenParam);
        const { email, clientNumber, token, cutlistId, missingArticleCodes } =
          data;
        login({ email, clientNumber, token });

        searchParams.delete('token');

        navigate(`/cutlist/${cutlistId}/parts?${searchParams.toString()}`, {
          state: { missingArticleCodes },
          replace: true,
        });
      } catch (err) {
        setError(err);
      }
    };

    handleCall();
  }, []);

  return { error };
}

export function usePageViews() {
  const location = useLocation();
  const source = useCurrentSource();

  React.useEffect(() => {
    window.analytics.page(undefined, undefined, { ticker: source });
  }, [location]);
}

export function useTogglePricing() {
  const { showPricing } = useCurrentFeatures();
  const urlParams = new URLSearchParams(window.location.search);
  const pricingParam = urlParamToBoolean(urlParams.get('p'));
  const noOption = pricingParam == null;

  if (noOption) return conditionallyTogglePricing(showPricing);

  conditionallyTogglePricing(pricingParam);
}

export const useIsNextDisabled = () => {
  const sheetGroups = useNestingStore((state) => state.sheetGroups);
  const partsHaveErrors = usePartsHaveErrors();
  const totalAmountExclVAT = usePriceExVat();
  const { showPricing } = useCurrentFeatures();
  const isLoggedIn = useIsLoggedIn();
  const isNextDisabled =
    (isLoggedIn && showPricing && !totalAmountExclVAT) ||
    partsHaveErrors ||
    sheetGroups.length === 0;

  return isNextDisabled;
};

export const resetStoreFn = () => {
  const priceStore = usePricingStore.getState();
  const cutlistStore = useCutlistState.getState();
  const userLeadStore = useLeadDetails.getState();
  const groupStore = useMaterialGroupState.getState();
  const deliveryAddressStore = useDeliveryAddress.getState();
  const nestingStore = useNestingStore.getState();

  return () => {
    groupStore.clear();
    cutlistStore.reset();
    userLeadStore.reset();
    deliveryAddressStore.reset();
    priceStore.reset();
    nestingStore.setNesting([]);
    resetErrors();
  };
};

type HydrateStoreOptions = {
  activeGroupArticleCode?: string; // pass an article code to activate the first group that contains it
  activeGroupId?: string; // pass a group id to activate it
  missingMaterials?: string[];
};

export const hydrateStoreFn = () => {
  const cutlistStore = useCutlistState.getState();
  const groupStore = useMaterialGroupState.getState();
  const userLeadStore = useLeadDetails.getState();
  const deliveryAddressStore = useDeliveryAddress.getState();
  const resetStore = resetStoreFn();
  const currentFeatures = getCurrentFeatures();

  const hydrateStore = (
    cutlist: CutlistOrder,
    options: HydrateStoreOptions = {}
  ) => {
    resetStore();

    cutlistStore.init({
      orderId: cutlist.id,
      shortId: cutlist.shortId,
      vatRate: cutlist.vatRate,
      title: cutlist.title || '',
      customerReference: cutlist.customerReference || '',
      deliverLeftoverMaterials: cutlist.deliverLeftoverMaterials,
      notes: cutlist.notes || '',
      status: cutlist.status,
      // drop time and keep date
      requestedDeliveryDate: cutlist.requestedDeliveryDate?.split('T')[0] || '',
      discountAmount: cutlist.discountAmount || undefined,
      discountPercentage: cutlist.discountPercentage || undefined,
      parts: cutlist.materialGroups
        .flatMap((g) => g.parts)
        .sort((partA, partB) => partA.index - partB.index)
        .map((part) => ({
          id: nanoid(6),
          groupId: part.groupId,
          label: part.label,
          quantity: part.quantity,
          grooves: part.grooves,
          edgebanding: {
            length1: part.length1Edgeband?.articleCode || null,
            length2: part.length2Edgeband?.articleCode || null,
            width1: part.width1Edgeband?.articleCode || null,
            width2: part.width2Edgeband?.articleCode || null,
          },
          roundedEdgeband: {
            length1: part.length1RoundedEdgeband,
            length2: part.length2RoundedEdgeband,
            width1: part.width1RoundedEdgeband,
            width2: part.width2RoundedEdgeband,
          },
          edgeProfile: {
            length1: part.length1EdgeProfile || 'none',
            length2: part.length2EdgeProfile || 'none',
            width1: part.width1EdgeProfile || 'none',
            width2: part.width2EdgeProfile || 'none',
          },
          grainDirection: part.grainDirection,
          createLabel: part.createLabel,
          widthMM: part.widthMM,
          lengthMM: part.lengthMM,
          thickness: part.thicknessUM,
          cncMinutes: part.cncMinutes,
          core: part.core1Material?.articleCode || null,
          core1: part.core1Material?.articleCode || null,
          core2: part.core2Material?.articleCode || null,
          topHpl: part.topHpl?.articleCode || null,
          bottomHpl: part.bottomHpl?.articleCode || null,
          partType: part.partType,
        })),
    });

    if (cutlist.materialGroups.length) {
      const missingGroups = (options.missingMaterials || []).map(
        (articleCode) =>
          articleCodeToMaterialGroupMapper(articleCode, currentFeatures)
      );

      const groups = cutlist.materialGroups
        .map((g) => materialGroupMapper(g))
        .concat(missingGroups);

      const activeGroupIdFromArticleCode =
        options.activeGroupArticleCode &&
        groups.find((g) => g.core1 === options.activeGroupArticleCode)?.id;

      const activeGroupId = options.activeGroupId
        ? cutlist.materialGroups.find(({ id }) => id === options.activeGroupId)
            ?.groupId
        : undefined;

      const activeGroup =
        activeGroupId ||
        activeGroupIdFromArticleCode ||
        cutlist.materialGroups[0]?.groupId;

      groupStore.init({ activeGroup, groups });
    } else if (options.missingMaterials?.length) {
      groupStore.addGroupsByArticleCodes(
        options.missingMaterials,
        currentFeatures,
        options.activeGroupArticleCode
      );
    } else {
      groupStore.addGroup();
    }

    const { userLeadDetail, userLead } = cutlist;

    const deliveryAddress = cutlist.addresses[0];
    if (deliveryAddress) {
      deliveryAddressStore.init({
        ...deliveryAddress,
        contactName: deliveryAddress.name,
      });
    }

    userLeadStore.init({
      ...userLeadDetail,
      ...userLead,
      name: userLead?.name || userLeadDetail?.name,
      notClient: false,
    });
  };

  return hydrateStore;
};
function materialGroupMapper(g: CutlistMaterialGroup): MaterialGroup {
  const type = g.parts.every((part) => part.partType === 'sheet')
    ? ('sheets-only' as MaterialGroup['type'])
    : ('panels-and-strips' as MaterialGroup['type']);

  const sheetEdgeTrimConfig = {
    sheetEdgeTrimType: g.sheetEdgeTrimType,
    trimThickness: {
      length1TrimThicknessMM: g.length1TrimThicknessMM || 0,
      length2TrimThicknessMM: g.length2TrimThicknessMM || 0,
      width1TrimThicknessMM: g.width1TrimThicknessMM || 0,
      width2TrimThicknessMM: g.width2TrimThicknessMM || 0,
    },
  };

  return {
    id: g.groupId,
    index: g.index,
    name: g.name,
    edgeProfile: g.edgeProfileType,
    continuousGrain: g.continuousGrain || '',
    additionalProcessing: g.additionalProcessing || '',
    core1: g.core1Material?.articleCode || null,
    core2: g.core2Material?.articleCode || null,
    topHpl: g.topHpl?.articleCode || null,
    bottomHpl: g.bottomHpl?.articleCode || null,
    edgeband: g.edgeband?.articleCode || null,
    materialSandwichType: g.materialSandwichType,
    sheetEdgeTrimConfig,
    sheetSizeSelection: 'manual', // PERSIST THIS AND HYDRATE WITH INCOMING DATA
    automaticSheetSizeMaterials: [],
    paintColor: g.paintColor,
    createLabels: g.createLabels,
    paintThicknessUM: g.paintThicknessUM,
    type,
  };
}
