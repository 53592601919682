import {
  Material,
  MaterialGroup,
  MaterialGroupState,
  PartItem,
} from '@cutr/constants/cutlist';
import { Features } from '@cutr/constants/cutlist-theme';
import { nanoid } from 'nanoid';
import React from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { findMatchingMaterialsWithFallback } from '@/utils/edgeband';
import {
  applyCustomTrimThickness,
  getTrimConfigByType,
} from '@/utils/features';
import { useDebounce } from '@/utils/hooks';
import { isTruthy } from '@/utils/misc';
import {
  getSmallestFittingMaterial,
  groupPartsByMaterial,
} from '@/utils/nesting';

import { useIsLoggedIn } from './login';
import {
  getEdgebandingMaterials,
  getHPLMaterials,
  getMaterial,
} from './materials';
import { useCutlistParts, useCutlistState } from './store';

export type MaterialComponent = 'core1' | 'topHpl' | 'bottomHpl' | 'edgeband';

type MaterialGroupStateActions = {
  addGroup(group?: Partial<MaterialGroup>): MaterialGroup['id'];
  addGroupsByArticleCodes(
    articleCodes: Material['articleCode'][],
    currentFeatures: Features,
    activeGroupArticleCode?: string
  ): void;
  setGroup(group: MaterialGroup): void;
  removeGroup(id: string): void;
  setActive(id: string): void;
  clear(): void;
  groupBy(): void;
  init(
    state: MaterialGroupState,
    options?: { missingMaterials?: string[] }
  ): void;
};

const generateMaterialGroup = (
  materialGroup?: Partial<MaterialGroup>
): MaterialGroup => ({
  id: nanoid(6),
  name: '',
  core1: null,
  core2: null,
  topHpl: null,
  bottomHpl: null,
  materialSandwichType: null,
  sheetEdgeTrimConfig: null,
  sheetSizeSelection: 'manual',
  automaticSheetSizeMaterials: [],
  edgeProfile: 'none',
  edgeband: null,
  createLabels: false,
  ...materialGroup,
});

export const setGroupAndParts = (group: MaterialGroup) => {
  useMaterialGroupState.getState().setGroup(group);
  useCutlistState.getState().updatePartsFromGroup(group);
};

export const updateAutomaticSheetSizeMaterials = (group: MaterialGroup) => {
  if (group.sheetSizeSelection !== 'automatic') return;
  const { parts } = useCutlistState.getState();
  const groupParts = parts.filter((p) => p.groupId === group.id);
  const updatedCore = getSmallestFittingMaterial(
    groupParts,
    group.automaticSheetSizeMaterials
  );

  if (group.core1 != updatedCore) {
    const updatedGroup = { ...group, core1: updatedCore };
    setGroupAndParts(updatedGroup);
  }
};

export const articleCodeToMaterialGroupMapper = (
  articleCode: string,
  currentFeatures: Features
) => {
  const { supportedSheetEdgeTrims, defaultSheetEdgeTrimTypeForPartGroup } =
    currentFeatures;

  let groupParam: Partial<MaterialGroup> = {
    core1: articleCode,
    materialSandwichType: 'single-core',
    type: 'panels-and-strips',
    sheetEdgeTrimConfig: getTrimConfigByType(
      supportedSheetEdgeTrims,
      defaultSheetEdgeTrimTypeForPartGroup
    ),
  };
  const hplMaterials = getHPLMaterials();
  const isHpl = !!hplMaterials.find((x) => x.articleCode === articleCode);
  const edgebandMaterials = getEdgebandingMaterials();
  const isEdgeband = !!edgebandMaterials.find(
    (x) => x.articleCode === articleCode
  );
  if (isHpl) {
    groupParam = {
      topHpl: articleCode,
      bottomHpl: articleCode,
      materialSandwichType: 'single-core-double-layer',
    };
  }
  if (isEdgeband) {
    groupParam = {
      edgeband: articleCode,
      materialSandwichType: 'single-core-double-layer',
    };
  }

  const materialGroup = generateMaterialGroup(groupParam);

  const material = getMaterial(articleCode);
  if (groupParam.type === 'panels-and-strips') {
    applyCustomTrimThickness(materialGroup, material);
  }

  return materialGroup;
};

export const useMaterialGroupState = create<
  MaterialGroupState & MaterialGroupStateActions
>()(
  devtools(
    (set, get) => ({
      groups: [],
      init: (state) => {
        const groups = [...state.groups];

        set(() => {
          return { ...state, groups };
        });
      },
      groupBy: () => {
        if (get().groups?.length) return;

        const { parts, setPart } = useCutlistState.getState();
        const groups = groupPartsByMaterial(parts);
        const materialGroups: MaterialGroup[] = [];
        const newParts: PartItem[] = [];

        if (!groups.size) {
          materialGroups.push(generateMaterialGroup());
        }

        groups.forEach((groupParts) => {
          if (!groupParts.length) return;

          const part = groupParts[0];
          const materialGroup = generateMaterialGroup({
            core1: part.core1,
            core2: part.core2,
            topHpl: part.topHpl,
            bottomHpl: part.bottomHpl,
          });

          materialGroups.push(materialGroup);
          groupParts.forEach((part) => {
            newParts.push({ ...part, groupId: materialGroup.id });
          });
        });

        set(() => ({
          groups: materialGroups,
          activeGroup: materialGroups[0].id,
        }));
        newParts.forEach((part) => setPart(part));
      },
      addGroup: (group = {}) => {
        const materialGroup = generateMaterialGroup(group);
        set((state) => {
          return {
            groups: [...state.groups, materialGroup],
            activeGroup: materialGroup.id,
          };
        });

        return materialGroup.id;
      },
      addGroupsByArticleCodes: (
        articleCodes,
        currentFeatures,
        activeGroupArticleCode
      ) => {
        set((state) => {
          const groups = [
            ...state.groups,
            ...articleCodes.map((articleCode) =>
              articleCodeToMaterialGroupMapper(articleCode, currentFeatures)
            ),
          ];

          const activeGroupId = groups.find(
            (g) => g.core1 === activeGroupArticleCode
          )?.id;

          return {
            groups,
            activeGroup: activeGroupId || state.activeGroup || groups[0]?.id,
          };
        });
      },
      setGroup: (group) => {
        set((state) => {
          const index = state.groups.findIndex((g) => g.id === group.id);
          const groups = state.groups.slice(0);

          if (groups[index]?.core1 !== group?.core1) {
            group.edgeProfile = 'none';
          }

          groups[index] = group;

          return { groups };
        });
      },
      removeGroup: (id) => {
        const { removePartsByGroup } = useCutlistState.getState();
        removePartsByGroup(id);
        set((state) => {
          const isSelected = state.activeGroup === id;
          const idx = state.groups.findIndex((g) => g.id == id);
          const groups = state.groups.filter((g) => g.id !== id);
          const next = Math.max(0, idx - 1);
          return {
            activeGroup: isSelected ? groups[next]?.id : state.activeGroup,
            groups,
          };
        });
      },
      setActive: (id) => set(() => ({ activeGroup: id })),
      clear: () => set(() => ({ groups: [], activeGroup: undefined })),
    }),
    { name: 'materialsGroup' }
  )
);

export const useMaterialGroups = () =>
  useMaterialGroupState((state) => state.groups);

const groupBySelector = (state: MaterialGroupStateActions) => state.groupBy;
export const useGroupBy = () => useMaterialGroupState(groupBySelector);

export const activeGroupSelector = (state: MaterialGroupState) =>
  state.groups.find((g) => g.id === state.activeGroup);
export const useActiveGroup = () => useMaterialGroupState(activeGroupSelector);

export const useActiveGroupParts = () => {
  const activeGroup = useActiveGroup();
  const parts = useCutlistState((state) => state.parts);
  return parts.filter((p) => p.groupId === activeGroup?.id);
};

const groupSelectorById = (state: MaterialGroupState, id: string) =>
  state.groups.find((g) => g.id === id);
export const useGroupById = (id: string) =>
  useMaterialGroupState((state) => groupSelectorById(state, id));

export const edgebandSelector = (state: MaterialGroupState) => {
  const activeGroup = activeGroupSelector(state);
  return Boolean(activeGroup?.edgeband);
};
export const useEdgebandSelected = () =>
  useMaterialGroupState(edgebandSelector);

export const sheetEdgeTrimConfigSelector = (state: MaterialGroupState) => {
  const activeGroup = activeGroupSelector(state);
  return activeGroup?.sheetEdgeTrimConfig;
};
export const useSheetEdgeTrimConfig = () =>
  useMaterialGroupState(sheetEdgeTrimConfigSelector);

export const useActiveGroupMaterials = () => {
  const group = useActiveGroup();
  if (!group) return {};

  const [core1, core2, topHpl, bottomHpl] = [
    group.core1,
    group.core2,
    group.topHpl,
    group.bottomHpl,
  ].map(getMaterial);

  return { core1, core2, topHpl, bottomHpl };
};

export const useMatchingMaterials = () => {
  const edgebandingMaterials = getEdgebandingMaterials();
  const sheetMaterials = useActiveGroupMaterials();
  return findMatchingMaterialsWithFallback(
    sheetMaterials,
    edgebandingMaterials
  );
};

export const useActiveGroupGrainDirection = () => {
  const materials = useActiveGroupMaterials();

  if (materials.topHpl || materials.bottomHpl) {
    const topHplGrainDirection = materials.topHpl?.hasGrainDirection;
    const topHplTextureDirection = materials.topHpl?.hasTextureDirection;
    const bottomHplGrainDirection = materials.bottomHpl?.hasGrainDirection;
    const bottomHplTextureDirection = materials.bottomHpl?.hasTextureDirection;
    return [
      topHplGrainDirection,
      topHplTextureDirection,
      bottomHplGrainDirection,
      bottomHplTextureDirection,
    ].some((it) => it === true);
  }
  return (
    materials.core1?.hasGrainDirection || materials.core1?.hasTextureDirection
  );
};

export const useEdgebandingMaterialForGroup = () => {
  const group = useActiveGroup();
  const parts = useCutlistParts();

  return Array.from(
    new Set(
      parts
        // you only want to check against the active group
        .filter((p) => p.groupId === group?.id)
        .flatMap((p) => [
          p.edgebanding?.length1,
          p.edgebanding?.length2,
          p.edgebanding?.width1,
          p.edgebanding?.width2,
        ])
    )
  ).filter(Boolean);
};

export function makeTitle(group: MaterialGroup) {
  const material = getMaterial(group.core1);
  const coreName =
    group.sheetSizeSelection === 'automatic'
      ? material?.variationGroup?.name || material?.name
      : material?.name;
  return [coreName || group.core1, group.topHpl, group.bottomHpl]
    .filter(Boolean)
    .join(' / ');
}

export const useSaveSensitiveData = () => {
  const group = useActiveGroup();
  const debouncedGroup = useDebounce<MaterialGroup | undefined>(group, 500);
  const { title } = useCutlistState();
  const debouncedTitle = useDebounce<string>(title, 500);

  const saveSensitiveData = React.useMemo(() => {
    const hasNotes = debouncedGroup
      ? Boolean(
          [
            debouncedGroup.continuousGrain,
            debouncedGroup.additionalProcessing,
          ].filter(isTruthy).length
        )
      : false;

    return {
      hasNotes,
      debouncedTitle,
    };
  }, [debouncedGroup, debouncedTitle]);

  return saveSensitiveData;
};

export const useActiveGroupPricingSensitiveData = () => {
  const activeGroup = useActiveGroup();
  const { discountAmount, discountPercentage } = useCutlistState();
  let {
    paintColor = null,
    paintThicknessUM = 0,
    edgeProfile,
    createLabels,
  } = activeGroup || {};
  const isLoggedIn = useIsLoggedIn();

  if (!paintThicknessUM) paintThicknessUM = 0;

  const priceSensitiveData = React.useMemo(() => {
    return {
      paintColor,
      paintThicknessUM,
      edgeProfile,
      createLabels,
      isLoggedIn,
      discountAmount,
      discountPercentage,
    };
  }, [
    paintColor,
    paintThicknessUM,
    edgeProfile,
    createLabels,
    isLoggedIn,
    discountAmount,
    discountPercentage,
  ]);

  return priceSensitiveData;
};
