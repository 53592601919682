import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useCutlistState, useSetTitle, useTitle } from '@/api/store';
import { onFocusSelect } from '@/hooks';
import { Input } from '@/primitives/Input';

import { CutlistOwnerButton } from './CutlistOwnerButton';

export const AgentQuoteHeader = () => {
  const title = useTitle();
  const setTitle = useSetTitle();
  const { t } = useTranslation();
  const shortId = useCutlistState((state) => state.shortId);
  const location = useLocation();

  const canAssignOwner = location.pathname.includes('/quote/parts');

  return (
    <div className="opposites">
      <div>
        <label htmlFor="cutlist-title">{shortId ?? ''}</label>
        <Input
          onInput={(e) => setTitle(e.currentTarget.value)}
          value={title}
          className="title"
          placeholder={t('cutlist-form.field.orderTitle.placeholder')}
          id="cutlist-title"
          onFocus={onFocusSelect}
        />
      </div>
      <CutlistOwnerButton readOnly={!canAssignOwner} />
    </div>
  );
};
