import { Theme } from '@cutr/constants/cutlist-theme';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useAuthStore } from '@/api/login';
import { useCutlistState } from '@/api/store';
import { hydrateStoreFn, resetStoreFn } from '@/hooks';
import { Button } from '@/primitives/Button';
import {
  ArrowLeft,
  Checkmark,
  Icon,
  Logout,
  Plus,
  Trash,
} from '@/primitives/Icons';
import { useCancelCutlist, useUpdateCutlistAgent } from '@/queries/agent';
import { useCreateEmptyCutlistByAgent } from '@/queries/crud';
import { useCurrentFeatures, useTheme, useThemeConfig } from '@/theme';

import { api } from '../api/backend';
import { ConfirmationDialog } from './ConfirmationDialog';
import { CutlistOwnerButton } from './CutlistOwnerButton';

export const AgentHeader = () => {
  const { t } = useTranslation();
  const config = useThemeConfig();
  const Logo = config.logo;
  const location = useLocation();
  const [isPending, setIsPending] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const resetStore = resetStoreFn();
  const { mutateAsync: updateCutlist } = useUpdateCutlistAgent();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const { mutateAsync: cancelCutlist } = useCancelCutlist();
  const { agentQuoteFlow } = useCurrentFeatures();
  const { status } = useCutlistState();

  const isEditing = location.pathname.includes('edit');
  const isOrdersPage = location.pathname.includes('orders') && !id;

  const { mutateAsync } = useCreateEmptyCutlistByAgent();
  const hydrateStore = hydrateStoreFn();

  const handleSave = async () => {
    setIsPending(true);
    if (!id) return;
    updateCutlist({ id })
      .then(() => {
        toast.success(t('common.cta.orderSaved'));
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleDiscard = () => {
    resetStore();
    navigate(`/admin/orders`);
  };

  const handleCreateNewCutlist = async () => {
    const cutlist = await mutateAsync();
    hydrateStore(cutlist);
    navigate(
      agentQuoteFlow
        ? `/admin/orders/${cutlist.id}/quote/parts`
        : `/admin/orders/${cutlist.id}/edit`,
      {
        state: { newOrder: true },
      }
    );
  };

  const confirmCancelOrder = () => {
    if (!id) {
      return;
    }

    cancelCutlist({ id })
      .then(() => {
        setIsConfirmDialogOpen(false);
        navigate('/admin/orders');
      })
      .catch((e) => {
        if (e.response.status === 409) {
          toast.error(t('agent.deletionDialog.error'));
        }
      });
  };

  return (
    <>
      <header>
        <a href={config.logoUrl || config.baseUrl} className="logo">
          <Logo />
        </a>
        <div
          className="flexAlign"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginLeft: '3rem',
          }}
        >
          <div>
            {id && isEditing && (
              <CutlistOwnerButton readOnly={status !== 'draft'} />
            )}
          </div>

          <div style={{ marginLeft: 'auto' }} className="flexAlign">
            {isOrdersPage && (
              <>
                <Button
                  icon={<Icon icon={<Plus />} />}
                  className="smaller header"
                  style={{
                    background: 'var(--header-cta-background)',
                    color: 'var(--header-cta-color)',
                  }}
                  onClick={() => handleCreateNewCutlist()}
                >
                  {t('common.newOrder')}
                </Button>
                <LogoutBtn />
              </>
            )}
            {isEditing && (
              <>
                <ConfirmationDialog
                  isOpen={isConfirmDialogOpen}
                  onClose={() => setIsConfirmDialogOpen(false)}
                  onConfirm={confirmCancelOrder}
                  title={t('agent.deletionDialog.title')}
                  variant="alert"
                >
                  {t('agent.deletionDialog.content')}
                </ConfirmationDialog>
                <Button
                  variant="header"
                  style={{
                    border: '1px solid var(--red-5)',
                    color: 'var(--red-5)',
                  }}
                  icon={<Icon icon={<Trash />} />}
                  onClick={() => setIsConfirmDialogOpen(true)}
                  disabled={isPending}
                >
                  {t('agent.deletionDialog.cta')}
                </Button>
                <Button
                  variant="header"
                  icon={<Icon icon={<Checkmark />} />}
                  onClick={handleSave}
                  disabled={isPending}
                >
                  {'Save'}
                </Button>
                <Button
                  variant="header"
                  icon={<Icon icon={<ArrowLeft />} />}
                  onClick={handleDiscard}
                  disabled={isPending}
                >
                  {t('common.cta.backTo', { name: t('common.cta.orders') })}
                </Button>
              </>
            )}
            <Suspense fallback={'...'}>
              <LanguageSelector />
            </Suspense>
          </div>
        </div>
      </header>
    </>
  );
};

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const currentLng = i18n.resolvedLanguage;

  const languagesForThemes = (theme: Theme) => {
    if (theme === 'swisskrono') return ['en', 'de'];
    if (theme === 'cutr') return ['en', 'de', 'nl'];

    return ['en', 'nl'];
  };

  const changeLanguage = async (lng: string) => {
    i18n.changeLanguage(lng);
    await api.updateUserLeadLocale();
  };

  return (
    <div className="flexAlign gap-xxs">
      {languagesForThemes(theme).map((lng) => {
        const style =
          lng === currentLng
            ? {
                fontWeight: 'bold',
                textDecoration: 'underline',
              }
            : undefined;
        return (
          <Button
            key={lng}
            variant="iconHeader"
            onClick={async () => await changeLanguage(lng)}
            style={style}
          >
            {t(`common.lng.${lng}`)}
          </Button>
        );
      })}
    </div>
  );
};

const LogoutBtn = () => {
  const { logout } = useAuthStore();
  const { t } = useTranslation();
  const { agent } = useAuthStore();

  const onLogout = () => {
    logout();
  };

  return (
    <Button
      variant="header"
      icon={<Icon icon={<Logout />} />}
      onClick={onLogout}
      className="tooltipBottom"
      tooltip={agent?.email}
    >
      {t('common.logout')}
    </Button>
  );
};
