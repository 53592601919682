import { CutlistOrder } from '@cutr/constants/cutlist';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';

import { api } from '@/api/backend';
import { cacheMaterials, materialsCached } from '@/api/materials';
import { usePricingStore } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { hydrateStoreFn } from '@/hooks';
import { getCutlistQuery } from '@/queries/crud';

export async function fetchMaterials() {
  const setHasMaterials = useCutlistState.getState().setHasMaterials;
  if (materialsCached()) return;

  await api
    .materials()
    .then(cacheMaterials)
    .then(() => setHasMaterials(true));
}

export const cutlistLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const query = getCutlistQuery(params.id as string);
    const hydrateStore = hydrateStoreFn();

    fetchMaterials();

    // cache hit
    const cutlistCache = queryClient.getQueryData<CutlistOrder>(query.queryKey);
    if (cutlistCache) {
      hydrateStore(cutlistCache);

      return cutlistCache;
    }

    const freshCutlist = await queryClient.fetchQuery(query);
    hydrateStore(freshCutlist);

    return freshCutlist;
  };

export const priceLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const query = getCutlistQuery(params.id as string);
    const priceStore = usePricingStore.getState();

    // cache hit
    const cutlistCache = queryClient.getQueryData<CutlistOrder>(query.queryKey);
    if (cutlistCache) {
      priceStore.setAmountVat(cutlistCache.totalAmountInclVAT);
      priceStore.setAmountNoVat(cutlistCache.totalAmountExclVAT);
      priceStore.setPricing(cutlistCache.pricingLineItems);
      priceStore.setError(null);

      return cutlistCache;
    }

    const freshCutlist = await queryClient.fetchQuery(query);
    priceStore.setAmountVat(freshCutlist.totalAmountInclVAT);
    priceStore.setAmountNoVat(freshCutlist.totalAmountExclVAT);
    priceStore.setPricing(freshCutlist.pricingLineItems);
    priceStore.setError(null);
    return freshCutlist;
  };
