import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { useDeliveryAddress } from '@/api/address';
import { ApiError } from '@/api/backend';
import { Button } from '@/primitives/Button';
import { AddUser, Icon, UserAvatar } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useAssignCutlistOwner } from '@/queries/crud';

import styles from './CutlistOwnerButton.module.css';
import { Modal } from './Modal';

export const CutlistOwnerButton = ({ readOnly }: { readOnly: boolean }) => {
  const { id } = useParams();
  const { mutateAsync: assignCutlistOwner } = useAssignCutlistOwner(
    id as string
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { init } = useLeadDetails();
  const [loading, setLoading] = React.useState(false);
  const deliveryAddressStore = useDeliveryAddress();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      const cutlist = await assignCutlistOwner(email as string);

      const deliveryAddress = cutlist.addresses[0];
      if (deliveryAddress) {
        deliveryAddressStore.init({
          ...deliveryAddress,
          contactName: deliveryAddress.name,
        });
      }

      init({
        ...cutlist.userLeadDetail,
        ...cutlist.userLead,
        notClient: false,
      });
      toast.success(t('agent.setCutlistOwnership.setOwnerSuccess', { email }));
      setLoading(false);
      setIsModalOpen(false);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {readOnly ? (
        <OwnerLabel />
      ) : (
        <OwnerButton onClick={() => setIsModalOpen(true)} />
      )}

      <Modal
        title=""
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <div className={styles.modalContent}>
          <div>
            <h2>{t('agent.setCutlistOwnership.setCutlistOwner')}</h2>
            <p>{t('agent.setCutlistOwnership.setOwnerInstructions')}</p>
          </div>
          <form
            className={styles.formContainer}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              onSubmit(e);
            }}
          >
            <div>
              <label htmlFor="email">
                {t(`agent.setCutlistOwnership.email`)} {'*'}
              </label>
              <Input type="email" name="email" required />
            </div>
            <Button type="submit" disabled={loading}>
              {t('agent.setCutlistOwnership.setOwner')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};

const OwnerButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return (
    <Button
      className="pill"
      variant="header"
      style={{
        background: 'var(--header-cta-background)',
        color: 'var(--header-cta-color)',
        minWidth: 'fit-content',
        pointerEvents: 'auto',
      }}
      icon={
        email ? <Icon icon={<UserAvatar />} /> : <Icon icon={<AddUser />} />
      }
      onClick={onClick}
    >
      <strong>
        {email
          ? t('cutlist-form.owner', { owner: email })
          : t('agent.setCutlistOwnership.setCutlistOwner')}
      </strong>
    </Button>
  );
};

const OwnerLabel = () => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return (
    <div className="flexAlign gap-xs">
      <Icon icon={<UserAvatar />} />
      <strong>{t('cutlist-form.owner', { owner: email })}</strong>
    </div>
  );
};
