import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { usePricingStore, useQuotePriceAggregate } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { currencyFormatter } from '@/utils/format';

import styles from './PriceSummary.module.css';

export const PriceSummary = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { vatRate } = useCutlistState();
  const priceStore = usePricingStore();
  const { totalAmountExclVAT, totalAmountInclVAT } = priceStore;
  const { pricePerGroup, additionalCosts } = useQuotePriceAggregate();

  return (
    <Card>
      <div className="opposites">
        <h3>{t('agent.quoteFlow.price')}</h3>
        <h3 className="isPrimary" data-cy="total-order-price">
          {currencyFormatter(totalAmountExclVAT / 100)}
        </h3>
      </div>
      <div className={styles.divider}>
        {t('cutlist-summary.price.total.excludingTaxes')}
      </div>

      {priceStore.error && (
        <p className="errors">{t('cutlist-summary.price.error')}</p>
      )}

      <ul className={cn(styles.cardPrice)}>
        {pricePerGroup.map(({ groupId, index, partCount, cost }) => {
          return (
            <React.Fragment key={`${groupId}-price-aggregate`}>
              <li className="flexAlign opposites">
                <div className="flexAlign gap-xs">
                  <span>
                    {t('agent.quoteEditor.materialNr', { count: index })}
                  </span>
                  {'•'}
                  <span>
                    {t('agent.quoteEditor.parts', { count: partCount })}
                  </span>
                </div>
                <span>{!cost && '€ ---.--'}</span>
                <span>{Boolean(cost) && currencyFormatter(cost / 100)}</span>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
      <strong>{t('agent.quoteEditor.additionalCosts')}</strong>
      <ul className={cn(styles.cardPrice, styles.divider)}>
        {additionalCosts.map(({ id, categoryName, totalAmount }) => {
          return (
            <React.Fragment key={id}>
              <li className="flexAlign opposites">
                <span>{categoryName}</span>
                <span>{!totalAmount && '€ ---.--'}</span>
                <span>
                  {Boolean(totalAmount) && currencyFormatter(totalAmount / 100)}
                </span>
              </li>
            </React.Fragment>
          );
        })}
      </ul>

      <ul className={styles.totalPrice}>
        <li className="flexAlign opposites">
          <span>
            {t('cutlist-summary.price.vatPercent', {
              vatRate,
            })}
          </span>
          <span>
            {currencyFormatter((totalAmountInclVAT - totalAmountExclVAT) / 100)}
          </span>
        </li>

        <li className={styles.bolder}>
          <strong>{t('cutlist-summary.price.total.includingVAT')}</strong>
          <strong>{currencyFormatter(totalAmountInclVAT / 100)}</strong>
        </li>
      </ul>

      {children}
    </Card>
  );
};
